$(function() {
  $('.menu-top-button, .menu-item-has-children').click(function(e) {
    $(this).toggleClass('active');
  });

  $('.menu-item-has-children').hover(
    function() {
      $('#menu-overlay').show();
    },
    function() {
      $('#menu-overlay').hide();
    }
  );

});

window.fbAsyncInit = function() {
  FB.init({
    appId   : '113200362426389',
    status  : true, // check login status
    cookie  : true, // enable cookies to allow the server to access the session
    xfbml   : true // parse XFBML
  });
};

function share(){
  FB.ui({
      method: 'feed',
      name:  'CSN Tests Online | ' + site_name,
      link: base_url,
      picture: base_url + '/assets/images/logo.png',
      caption: '',
      description: share_text + ' ' + base_url
      },
      function(response) { }
  );
}
